$(document).ready(function () {
    let list = $('.ajax-multiselect');
    $.each(list, function (_index) {
        let $this = $(this)
        initSelect2($(this), $this.data('search'), $this.data('placeholder'));
    });
});

function initSelect2(selectName, searchUri, placeholder) {
    let selectField = $(selectName);

    if (selectField) {
        if (searchUri) {
            selectField.select2({
                ajax: {
                    url: searchUri,
                    dataType: 'json',
                    delay: 250,
                    data: function (params) {
                        return {
                            search: params.term,
                        };
                    },
                    cache: true
                },
                theme: "bootstrap4",
                placeholder: placeholder,
                minimumInputLength: 2,
                allowClear: true,
                // multiple: true,
                // maximumSelectionLength: 1,
                // tags: true,
            });
        } else {
            selectField.select2({
                theme: "bootstrap4",
                placeholder: placeholder,
                allowClear: true,
                // multiple: true,
                // maximumSelectionLength: 1,
                // tags: true,
            });
        }

        if (typeof select2Changed === "function") {
            selectField.on('select2:select', function (e) {
                select2Changed(e.target.id, e.params.data.text)
            });
        }

    }
}
