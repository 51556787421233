require('./bootstrap');
require("datatables.net-bs4");
require('./datatables-startup');
require('select2');
require('./select2-startup');
require('bootstrap-datepicker');
require('./bootstrap-datepicker-startup');
require('chart.js/dist/chart');
require('./chartjs-startup');

if (typeof documentReady === "function") {
    $(document).ready(function() {
        documentReady();
    });
}
