$(document).ready(function() {

    function stringToDate(value) {
        let dateParts = value.split("/");
        if (dateParts.length === 3) {
            let day = parseInt(dateParts[0], 10),
                month = parseInt(dateParts[1], 10),
                year = parseInt(dateParts[2], 10);
            if (year < 100) {
                if (year > 69) {
                    year = 1900 + year;
                } else {
                    year = 2000 + year;
                }
            }
            const theDate = new Date(Date.UTC(year, month - 1, day));
            if (theDate && !isNaN(theDate.getTime())) {
                return theDate;
            }
        }

        const timestamp = Date.parse(value);
        if (isNaN(timestamp) === false) {
            return new Date(timestamp);
        }
        return null;
    }

    function dateToString(d, iso) {
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        if (month < 10) {
            month = '0' + month;
        }
        let day = d.getDate();
        if (day < 10) {
            day = '0' + day;
        }
        if (iso) {
            return year + '-' + month + '-' + day;
        }
        return day + '/' + month + '/' + year;
    }

    $('.datepicker')
        .datepicker({
            autoclose: true,
            // orientation: 'bottom auto',
            format: {
                toDisplay: function (date, format, language) {
                    return dateToString(date, false);
                },
                toValue: function (date, _format, language) {
                    return stringToDate(date);
                }
            }
        })
});
