$(document).ready(function() {
    let reports = $('.datatable');
    $.each(reports, function (index) {
        initDataTable($(this), $(this).data('search'));
    });
});

function initDataTable(tableName, searchUri) {
    var table = $(tableName);

    if (table) {
        // Copy data table column specs from data attributes for better separation between display and logic
        var columns = initColumns(table);

        // Fire up the data table
        var tableObject = table.DataTable({
            "columns": columns,
            "processing": false,
            "serverSide": true,
            "pageLength": 15,
            "lengthMenu": [[15, 30, 50], [15, 30, 50]],
            "ajax": searchUri,
            "stateSave": true,
            "initComplete": function(/* settings , json */) {
                table.removeClass('invisible');
            }
        });

        /**
         * Uses TH data attributes to create the array of columns needed by DataTables.
         *
         * @param {Object} table HTML table to be used
         * @return {string[][]} Array of column data and name elements
         */
        function initColumns(table) {
            // Copy data table column specs from data attributes for better separation between display and logic
            var columns = [];
            table.find('th').each(function() {
                var $this = $(this);
                var data = $this.data('data');
                var name = $this.data('data');
                columns.push({'data': data, 'name': name});
            });
            return columns;
        }

    }

}
