window.chartColors = [
    /* red */ 'rgb(204, 0, 0)',
    /* blue */ 'rgb(0, 0, 204)',
    /* green */ 'rgb(0, 204, 0)',
    /* orange */ 'rgb(255, 153, 0)',
    /* yellow */ 'rgb(255, 205, 86)',
    /* purple */ 'rgb(153, 102, 255)',
    /* black */ 'rgb(0, 0, 0)',
    /* teal */ 'rgb(0, 255, 255)',
    /* grey */ 'rgb(201, 203, 207)'
];

function getChartDataset(index, label, data) {
    return {
        label: label,
        backgroundColor: window.chartColors[index],
        borderColor: window.chartColors[index],
        data: data,
        fill: false
    };
}

function getChartConfig(title, xLabel, yLabel, chartType, chartData, baseColourIndex) {
    let datasets = [];
    let max = chartData.length - 1;
    let displayTitle = title.length > 0;
    let showLines = true;

    if (typeof baseColourIndex === 'undefined') {
        baseColourIndex = 0;
    }

    for (let index = 0; index < max; index++) {
        datasets.push(
            getChartDataset(baseColourIndex + index,
                chartData[index + 1][0],
                chartData[index + 1][1]
            )
        );
    }

    if (chartType === 'scatter') {
        chartType = 'line';
        showLines = false;
    }

    return {
        type: chartType,
        data: {
            labels: chartData[0],
            datasets: datasets
        },
        options: {
            responsive: true,
            title: {
                display: displayTitle,
                text: title
            },
            showLines: showLines,
            scales: {
                xAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true,
                        labelString: xLabel
                    }
                }],
                yAxes: [{
                    display: true,
                    ticks: {
                        beginAtZero:true
                    },
                    scaleLabel: {
                        display: true,
                        labelString: yLabel
                    }
                }]
            }
        }
    };
}

window.getChartConfig = getChartConfig;
